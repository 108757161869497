const ignoredQuestionUserCodes = [
  'acgUserID',
  'acgFormID',
  'acgSubmissionID',
  'acgCitizenID',
  'acgSchemaID',
  'acgSubmissionDatetime',
  'acgSubmissionDateTime' // Some older templates have a variation on the QUC
];

const ignoredQuestion = (questionNode: any) => {
  const questionUserCode = questionNode.getAttribute('questionUserCode');
  return (
    questionUserCode && ignoredQuestionUserCodes.includes(questionUserCode)
  );
}

const questionDataTypes = new Map([
  ['SHORT_TEXT', 'textfield'],
  ['LONG_TEXT', 'textareafield'],
  ['DATE', 'datefield'],
  ['BOOLEAN', 'checkboxfield'],
  ['NUMBER', 'monetaryfield'],
  ['LOOKUP', 'dropdownfield'], // a lookup is either a dropdown or a radio button. defaulting to dropdown for now
]);

const numberPropertiesMap = new Map([
  // TODO: These need to be revised once ACG can support more ways of configuring number fields
  ['mo:Decimal11d2', { max: 9 }],
  ['mo:Long9Digits', { max: 9 }],
  ['mo:NonNegativeLong9Digits', { max: 9 }],
  ['mo:NonNegativeShort', { max: 4 }], // This is max 9999. No negatives
  ['mo:PositiveDecimal5d2', { max: 5 }], // This is <1000. 2 decimal places. So 999.99. No negatives
]);

const convertQuestion = (questionNode: any, optionsMap: any, lookupTypeMap: any) => {
  if (ignoredQuestion(questionNode)) {
    return;
  }

  const answerDatatype = questionNode.getAttribute('answerDatatype');
  let question: any = undefined;
  if (
    answerDatatype === 'SHORT_TEXT' ||
    answerDatatype === 'LONG_TEXT' ||
    answerDatatype === 'DATE' ||
    answerDatatype === 'BOOLEAN' ||
    answerDatatype === 'LOOKUP' ||
    answerDatatype === 'NUMBER'
  ) {
    question = {
      id: questionNode.getAttribute('questionUserCode'),
      text: questionNode.getAttribute('questionText'),
      type: questionDataTypes.get(answerDatatype),
      mandatory: questionNode.getAttribute('mandatory') === 'true',
      properties: {},
      helper: '',
      validation: null,
      defaultValue: null,
      options: [],
    };
  } else {
    // console.log('Unknown answerDatatype: ' + answerDatatype);
  }

  if (answerDatatype === 'LOOKUP') {
    const options = optionsMap.get(questionNode.getAttribute('questionLookupSet'));
    question['options'] = options;

    const lookupType = lookupTypeMap.get(questionNode.getAttribute('questionUserCode'));
    if (lookupType) {
      question['type'] = lookupType;
    }
  }

  if (answerDatatype === 'DATE') {
    question.properties['min'] = '1900-01-01';
    question.properties['max'] = null;
    question.properties['allowedExtensions'] = null;
    question.properties['readonly'] = false;
  }

  if (answerDatatype === 'NUMBER') {
    const numberProperties = numberPropertiesMap.get(questionNode.getAttribute('validationRule'));
    if (numberProperties) {
      question.properties['allowedExtensions'] = null;
      question.properties['readonly'] = false;
      question.properties = Object.assign(question.properties, numberProperties);
    } else {
      // We have some legacy converted templates which use rule id 5 for number but does not set the validation rule.
      // Since rule id 5 allows fraction digits and has a large range accepting negatives we can just use the closest match which is decimal11d2
      question.properties['allowedExtensions'] = null;
      question.properties['readonly'] = false;
      question.properties = Object.assign(
        question.properties,
        numberPropertiesMap.get('mo:Decimal11d2'),
      );
    }
  }
  if (!question.properties['min'] && !question.properties['max']) {
    question.properties['min'] = null;
    question.properties['max'] = null;
    question.properties['allowedExtensions'] = null;
    question.properties['readonly'] = false;
  }
  return question;
};

const constructLookupTypeMap = (designViewNode: any) => {
  const lookupTypeMap = new Map();

  const addToMap = (inputNode: any) => {
    const appearance = inputNode.getAttribute('appearance');
    if (appearance === 'radiobutton') {
      lookupTypeMap.set(inputNode.getAttribute('questionUserCode'), 'radiobuttonfield');
    } else if (appearance === 'dropdown') {
      lookupTypeMap.set(inputNode.getAttribute('questionUserCode'), 'dropdownfield');
    }
  };

  const recursivelyAddInputsToMap = (node: any) => {
    if (node.nodeName === 'Input') {
      addToMap(node);
    } else {
      for (const child of node.children) {
        recursivelyAddInputsToMap(child);
      }
    }
  };

  for (const child of designViewNode.children) {
    recursivelyAddInputsToMap(child);
  }

  return lookupTypeMap;
};

const constructOptionsMap = (modelNode: any) => {
  const optionsMap = new Map();

  for (const child of modelNode.children) {
    if (child.nodeName === 'QuestionLookupSet') {
      const lookupSetRef = child.getAttribute('ref');
      const options: any = [];

      child.childNodes.forEach((questionLookupSetChild: any) => {
        if (questionLookupSetChild.nodeName === 'QuestionLookup') {
          const questionLookup = questionLookupSetChild;
          const option = {
            value: questionLookup.getAttribute('questionLookupUserCode'),
            text: questionLookup.getAttribute('answer'),
            declaration: null,
          };
          options.push(option);
        }
      });
      optionsMap.set(lookupSetRef, options);
    }
  }

  return optionsMap;
};

const convertQuestions = (modelNode: any, designViewNode: any) => {
  const optionsMap = constructOptionsMap(modelNode);
  const lookupTypeMap = constructLookupTypeMap(designViewNode);

  const questions = [];
  for (const child of modelNode.children) {
    if (child.nodeName === 'Question') {
      const question = convertQuestion(child, optionsMap, lookupTypeMap);
      if (question) {
        questions.push(question);
      }
    } else if (child.nodeName === 'QuestionLookupSet') {
      // Options for radios or dropdowns. Map constructed already
    } else if (child.nodeName === 'QuestionCollection') {
      for (const questionCollectionNode of child.children) {
        if (questionCollectionNode.nodeName === 'Question') {
          const question = convertQuestion(questionCollectionNode, optionsMap, lookupTypeMap);
          if (question) {
            questions.push(question);
          }
        } else {
          console
            .log
            // 'Unknown question collection child node type: ' + questionCollectionNode.nodeName,
            ();
        }
      }
    } else {
      //  console.log('Unknown question node type: ' + child.nodeName);
    }
  }
  return questions;
};

export default convertQuestions;
